import store from '../store'
import { UserTraits } from '../types/traits'

export default class Traits {
  private _traits!: UserTraits

  public constructor() {
    this.set(this.load() || {})
  }

  private load() {
    return store.get('traits') || {}
  }

  public set(traits: UserTraits) {
    this._traits = traits

    store.set('traits', traits)
  }

  public add(traits: UserTraits) {
    const updatedTraits = {
      ...this._traits,
      ...traits,
      address: {
        ...this._traits.address,
        ...traits.address,
      },
    }

    this.set(updatedTraits)
  }

  public get() {
    return this._traits
  }

  public reset() {
    this.set({})
  }
}
