import { Event } from '../Event'
import { ViewerContext } from '../../constants'

export interface ProductForExpertFeedbackUnsavedEventProperties {
  source: string
  sellableId: string
  parentSellableId?: string | null
}

export class ProductForExpertFeedbackUnsavedEvent extends Event<
  ProductForExpertFeedbackUnsavedEventProperties
> {
  static displayName = 'Product For Expert Feedback Unsaved'

  static allowedViewerContexts = [ViewerContext.Consumer, ViewerContext.BusinessUser]
}
