import { ViewerContext } from '../constants'
import uuidv4 from 'uuid/v4'

// Event properties must be JSON-serializable objects
export type OptionalEventProperties = object | void

export class Event<TProperties extends OptionalEventProperties = void> {
  static displayName: string

  static allowedViewerContexts: ViewerContext[] = []

  public id: string
  public properties: TProperties

  public constructor(properties: TProperties) {
    this.id = uuidv4()

    this.properties = properties
  }

  /**
   * Get the display name for this event type.
   *
   * Event display names are static per event type. This is only exposed
   *   as an instance property for convenience, esp. since TypeScript is
   *   unhappy with the implementation detail of accessing a static property
   *   off of the `constructor`.
   */
  public get displayName() {
    return (this.constructor as typeof Event).displayName
  }

  /**
   * Get the viewers that are permitted to fire this event type.
   *
   * The allowed identities are static per event type. This is only exposed
   *   as an instance property for convenience, esp. since TypeScript is
   *   unhappy with the implementation detail of accessing a static property
   *   off of the `constructor`.
   */
  public get allowedViewerContexts() {
    return (this.constructor as typeof Event).allowedViewerContexts
  }
}
