import { Event } from '../Event'
import { ViewerContext } from '../../constants'

export class PageInteractedEvent extends Event {
  static displayName = 'Page Interacted'

  static allowedViewerContexts = [
    ViewerContext.Consumer,
    ViewerContext.BusinessUser,
    ViewerContext.PartnerUser,
    ViewerContext.Operator,
  ]
}
