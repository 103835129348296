/**
 * Important: In addition to being compiled as part of the main client/snippet, this file is
 *   separately compiled as a standalone module so that it can be imported safely in a Node
 *   runtime. This is necessary because our server-side app needs to access the list of query
 *   parameters used by the tracking library, in order to preserve them when issuing internal
 *   redirects.
 *
 * This file should not import anything, or contain any browser-reliant code.
 *
 * See `components/Redirect` in the the Consumer app, for context.
 */
export enum TrackingQueryParameter {
  ad_id = 'ad_id',
  ad_type = 'ad_type',
  ad_search_query = 'ad_search_query',
  adgroup_id = 'adgroup_id',
  expert_id = 'expert_id',
  campaign_id = 'campaign_id',
  department = 'department',
  gclid = 'gclid',
  fbclid = 'fbclid',
  fb_placement = 'fb_placement',
  fb_site_source_name = 'fb_site_source_name',
  product_id = 'product_id',
  referrer_id = 'referrer_id',
  target_id = 'target_id',
  c_adgroup = 'c_adgroup',
  c_medium = 'c_medium',
  c_campaign = 'c_campaign',
  c_term = 'c_term',
  c_content = 'c_content',
  c_source = 'c_source',
  c_id = 'c_id',
  utm_adgroup = 'utm_adgroup',
  utm_medium = 'utm_medium',
  utm_campaign = 'utm_campaign',
  utm_term = 'utm_term',
  utm_content = 'utm_content',
  utm_source = 'utm_source',
  utm_id = 'utm_id',
  vt_match_type = 'vt_match_type',
  vt_device = 'vt_device',
  vt_device_model = 'vt_device_model',
  vt_geo_location = 'vt_geo_location',
  vt_interest_location = 'vt_interest_location',
  vt_placement = 'vt_placement',
  vt_target_id = 'vt_target_id',
  vt_ad_position = 'vt_ad_position',
  vt_network = 'vt_network',
}

/**
 * The external API also requires access to the Identity enum, to use as the 2nd argument of
 *   the `identify()` method
 */
export enum ViewerContext {
  Consumer,
  BusinessUser,
  Operator,
  PartnerUser,
}
