import Adjust from '@adjustcom/adjust-web-sdk'
import { SpecificTracker } from '../types/tracker'
import * as Events from '../events'

export interface AdjustTrackerOptions {
  environment: string
}

export default class AdjustTracker implements SpecificTracker {
  private options: AdjustTrackerOptions

  public constructor(options: AdjustTrackerOptions) {
    this.options = options

    Adjust.initSdk({
      appToken: 'f0wcdh2r8ge8',
      environment: this.options.environment,
    })
  }

  public trackUserRegistered() {
    Adjust.trackEvent({
      eventToken: 'd0m9jr',
    })
  }

  public trackLeadCreated() {
    Adjust.trackEvent({
      eventToken: 'lj493k',
    })
  }

  public trackCuratedListViewed() {
    Adjust.trackEvent({
      eventToken: 'ejaib8',
    })
  }

  public trackOrderCompleted({ properties }: Events.OrderCompletedEvent) {
    Adjust.trackEvent({
      eventToken: 'amul2y',
      revenue: parseFloat(properties.value),
      currency: 'USD',
    })
  }
}
