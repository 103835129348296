import { Event } from '../Event'
import { ViewerContext } from '../../constants'

export interface UserActionEventProperties {
  page?: string
  element?: string
}

export class UserActionEvent extends Event<UserActionEventProperties> {
  static displayName = 'User Action'

  static allowedViewerContexts = [
    ViewerContext.Consumer,
    ViewerContext.BusinessUser,
    ViewerContext.PartnerUser,
    ViewerContext.Operator,
  ]
}
