import { Event } from '../Event'
import { ViewerContext } from '../../constants'
import { SellableProperties } from '../types/consumer'

export interface CuratedListViewedEventProperties {
  items: (SellableProperties & { quantity: number })[]
  currency: string
  lead_id: string
  activation_id?: string
  category_slug: string | null
  content_category: string | null
  lead_category_slug: string
  first_view: boolean
  sellables_awaiting_feedback?: number
}

export class CuratedListViewedEvent extends Event<CuratedListViewedEventProperties> {
  static displayName = 'Curated List Viewed'

  static allowedViewerContexts = [ViewerContext.Consumer]
}
