import TrackingClient from '../TrackingClient'
import { SpecificTracker, TrackingContext } from '../types/tracker'
import { UserTraits } from '../types/traits'
import { SellableProperties } from '../events/types/consumer'
import * as Events from '../events'

export interface FacebookTrackerOptions {
  pixelId: string
}

export default class FacebookTracker implements SpecificTracker {
  private options: FacebookTrackerOptions
  private client: TrackingClient
  private executeAfterTraitsQueue: Array<() => any>

  public constructor(options: FacebookTrackerOptions, client: TrackingClient) {
    if (!window.fbq) {
      throw new Error(
        'The Facebook Pixel snippet is missing, but must be installed to use the FacebookTracker.'
      )
    }

    this.options = options
    this.client = client
    this.executeAfterTraitsQueue = []

    if (client.getCCPAConsent() === false) {
      // @ts-ignore Facebook Pixel typings are incomplete
      this.fbq('dataProcessingOptions', this.options.pixelId, ['LDU'], 0, 0)
    }

    // Initialize the pixel
    this.fbq('init', this.options.pixelId, this.getAdvancedMatchingData())
  }

  private get fbq() {
    return window.fbq!
  }

  /**
   * If the user opts out of data collection in response to a CCPA banner, we enable
   *   Meta's "Limited Data Use" setting for the user.
   *
   * See: https://developers.facebook.com/docs/meta-pixel/implementation/data-processing-options/
   */
  public optOutCCPA() {
    // @ts-ignore Facebook Pixel typings are incomplete
    this.fbq('dataProcessingOptions', this.options.pixelId, ['LDU'], 0, 0)
    this.fbq('init', this.options.pixelId, this.getAdvancedMatchingData())
  }

  /**
   * If the user opts in to data collection in response to a CCPA banner, we explicitly
   *   disable Meta's "Limited Data Use" setting for the user.
   *
   * See: https://developers.facebook.com/docs/meta-pixel/implementation/data-processing-options/
   */
  public optInCCPA() {
    // @ts-ignore Facebook Pixel typings are incomplete
    this.fbq('dataProcessingOptions', this.options.pixelId, [])
    this.fbq('init', this.options.pixelId, this.getAdvancedMatchingData())
  }

  /**
   * Re-enable data collection and reinstantiate the pixel when the user logs out.
   */
  public reset() {
    this.executeAfterTraitsQueue = []

    // @ts-ignore Facebook Pixel typings are incomplete
    this.fbq('dataProcessingOptions', this.options.pixelId, [])
    this.fbq('init', this.options.pixelId, this.getAdvancedMatchingData())
  }

  /**
   * When a user's traits have changed, we must re-initialize the pixel in order to
   *   associate the new traits with that pixel.
   *
   * The Facebook documentation suggests this is bad practice, or an error, but others
   *   say it's supported and is the only way to add traits to a pixel without refreshing
   *   the page (i.e. in a single page app).
   *
   * See: https://stackoverflow.com/questions/39355191/advanced-matching-with-new-facebook-pixel-in-javascript-web-app
   */
  public addTraits(traits: UserTraits) {
    const advancedMatchingData = this.getAdvancedMatchingData(traits)

    this.fbq('init', this.options.pixelId, advancedMatchingData)

    this.executeAfterTraitsQueue.forEach((callback) => {
      callback()
    })
  }

  /**
   * A wrapper for the Facebook Pixel tracking API (`fbq`).
   *
   * Events are always tracked to a default pixel ID, and are additionally tracked to a
   *   department-specific pixel ID if the user was associated with a department when
   *   they were acquired. If the department is not known at the time the event is fired
   *   those events are queued in case they are associated with a department later on
   *   (at which point all queued events are flushed to the department-specific pixel)
   */
  private trackSingle(
    eventId: string,
    eventName: string,
    parameters?: { [parameterName: string]: any }
  ) {
    // @ts-ignore Facebook Pixel typings are incomplete
    this.fbq('trackSingle', this.options.pixelId, eventName, parameters, {
      eventID: eventId,
    })
  }

  /**
   * Same as `track`, but for custom events.
   *
   * See: https://developers.facebook.com/docs/facebook-pixel/implementation/conversion-tracking#custom-events
   */
  private trackSingleCustom(
    eventId: string,
    eventName: string,
    parameters?: { [parameterName: string]: any }
  ) {
    // @ts-ignore Facebook Pixel typings are incomplete
    this.fbq('trackSingleCustom', this.options.pixelId, eventName, parameters, {
      eventID: eventId,
    })
  }

  /**
   * Same as `track`, but waits up to `maximumDelayMs` before tracking the event. If `addTraits`
   *   is called during that interval, the event is tracked immediately.
   *
   * This is used to ensure user traits are available before logging an event, specifically
   *   "CompleteRegistration" which is not guaranteed to be tracked by the application before the
   *   user's traits have been updated. Having the user traits saved is important to improve
   *   match rates (see `getAdvancedMatchingDataFromUserTraits`).
   */
  private trackAfterTraits(
    eventId: string,
    eventName: string,
    parameters?: { [parameterName: string]: any },
    maximumDelayMs: number = 2500
  ) {
    // Remove the event from the queue
    const dequeue = () => {
      // And remove it from the queue
      const indexOfQueuedEvent = this.executeAfterTraitsQueue.findIndex(
        (callback) => callback === track
      )
      if (indexOfQueuedEvent !== -1) {
        this.executeAfterTraitsQueue.splice(indexOfQueuedEvent, 1)
      }
    }

    // Track the event (for later)
    const track = () => {
      this.trackSingle(eventId, eventName, parameters)

      // Make sure it's not queued anymore
      dequeue()

      // And make sure the timer is unset
      clearTimeout(timeout)
    }

    // Set a timer to track the event
    const timeout = setTimeout(track, maximumDelayMs)

    // Also queue the event to track when addTraits is called (whichever happens first)
    this.executeAfterTraitsQueue.push(track)
  }

  /**
   * Get advanced matching data for the current user.
   *
   * See: https://developers.facebook.com/docs/facebook-pixel/advanced/advanced-matching/
   */
  private getAdvancedMatchingData(traits?: UserTraits) {
    const advancedMatchingData: Record<string, any> = {
      // Always set `external_id` for event deduplication purposes
      //   See: https://developers.facebook.com/docs/marketing-api/conversions-api/deduplicate-pixel-and-server-events/
      external_id: (this.client.userId || this.client.sessionId)?.toLowerCase(),
    }

    // Set additional advanced matching parameters based on user traits
    if (traits) {
      // Format date of birth as yyyymmdd
      let formattedDateOfBirth = ''
      if (traits.dateOfBirth) {
        const birthYear = traits.dateOfBirth.getFullYear()
        const birthMonth = traits.dateOfBirth.getMonth()
        const birthDate = traits.dateOfBirth.getDate()

        formattedDateOfBirth += birthYear.toString()
        formattedDateOfBirth +=
          birthMonth < 10 ? `0${birthMonth.toString()}` : birthMonth.toString()
        formattedDateOfBirth += birthDate < 10 ? `0${birthDate.toString()}` : birthDate.toString()
      }

      Object.assign(advancedMatchingData, {
        ct:
          traits.address &&
          traits.address.city &&
          traits.address.city.toLowerCase().replace(/\s/g, ''),
        // TODO: Normalize state to two-letter state code
        st: traits.address && traits.address.state && traits.address.state.toLowerCase(),
        // TODO: Normalize country to two-letter country code
        country: traits.address && traits.address.country?.toLowerCase(),
        zp: traits.address && traits.address.postalCode,
        db: traits.dateOfBirth && formattedDateOfBirth,
        em: traits.email && traits.email.toLowerCase(),
        fn: traits.firstName && traits.firstName.toLowerCase(),
        ln: traits.lastName && traits.lastName.toLowerCase(),
        ge: traits.gender,
        ph: traits.phone && traits.phone.replace(/[^\d]/g, ''),
      })
    }

    return advancedMatchingData
  }

  /**
   * Convert our internal tracking representation of a sellable to the format Facebook
   *   expects.
   *
   * See: https://developers.facebook.com/docs/facebook-pixel/implementation/conversion-tracking#object-properties
   */
  private getFacebookContentsFromSellableTrackingProperties(
    sellable: SellableProperties & { quantity?: number }
  ) {
    return {
      id: sellable.product_id,
      quantity: sellable.quantity || 1,
      item_price: sellable.price || undefined,
    }
  }

  public trackPageViewed({ id }: Events.PageViewedEvent) {
    this.trackSingle(id, 'PageView')
  }

  public trackCheckoutStarted({ id, properties }: Events.CheckoutStartedEvent) {
    const contents = properties.products.map(this.getFacebookContentsFromSellableTrackingProperties)
    const contentIds = contents.map((content) => content.id)
    const contentCategory =
      properties.products.length > 0 ? properties.products[0].category : undefined

    this.trackSingle(id, 'InitiateCheckout', {
      content_category: contentCategory,
      content_ids: contentIds,
      // @ts-ignore Facebook Pixel typings are incomplete
      contents,
      currency: properties.currency,
      num_items: contents.length,
      value: parseFloat(properties.value),
    })
  }

  public trackOrderCompleted({ id, properties }: Events.OrderCompletedEvent) {
    const contents = properties.products.map(this.getFacebookContentsFromSellableTrackingProperties)
    const contentIds = contents.map((content) => content.id)

    // We use margin-based bidding for the value of our purchase conversion events
    let value = parseFloat(properties.assumed_margin)

    this.trackSingle(id, 'Purchase', {
      content_ids: contentIds,
      // TODO: What should 'content_name' be for a new request?
      // content_name: ,
      content_type: 'product',
      // @ts-ignore Facebook Pixel typings are incomplete
      contents,
      num_items: contents.length,
      value,
      currency: properties.currency,
      // Track the department of the most expensive item in the order (useful for retargeting)
      department: properties.department,
    })
  }

  public trackProductViewed({ id, properties }: Events.ProductViewedEvent) {
    const content = this.getFacebookContentsFromSellableTrackingProperties(properties)

    this.trackSingle(id, 'ViewContent', {
      // TODO: Verify this is correct. If we only track parent sellables we may want to use 'product_group'.
      content_type: 'product',
      // @ts-ignore Facebook Pixel typings are incomplete
      contents: [content],
      content_ids: [content.id],
      content_name: properties.name,
      content_category: properties.category,
      currency: properties.currency,
      // TODO: Unclear whether this represents the "value of the user to the business" (per documentation),
      //   or the value of the sellable.
      value: properties.price ? parseFloat(properties.price) : undefined,
    })
  }

  public trackProductAdded({ id, properties }: Events.ProductAddedEvent) {
    const content = this.getFacebookContentsFromSellableTrackingProperties(properties)

    this.trackSingle(id, 'AddToCart', {
      content_type: 'product',
      // @ts-ignore Facebook Pixel typings are incomplete
      contents: [content],
      content_ids: [content.id],
      content_name: properties.name,
      content_category: properties.category,
      currency: properties.currency,
      // TODO: Unclear whether this represents the "value of the user to the business" (per documentation),
      //   or the value of the sellable.
      value: properties.price ? parseFloat(properties.price) : undefined,
    })
  }

  public trackLeadCreated({ id, properties }: Events.LeadCreatedEvent) {
    this.trackSingle(id, 'Lead', {
      department: properties.department_slug,
      category: properties.category_slug,
    })

    // Temporary test to see if a new event without as much history is optimized differently
    this.trackSingleCustom(id, 'Browser Lead', {
      department: properties.department_slug,
      category: properties.category_slug,
    })

    // Track "MQL v0" conversion event
    if (properties.marketing_qualified) {
      this.trackSingleCustom(id, 'MQL v0', {
        department: properties.department_slug,
        category: properties.category_slug,
      })
    }
  }

  public trackProductsSearchResultsViewed({
    id,
    properties,
  }: Events.ProductsSearchResultsViewedEvent) {
    this.trackSingle(id, 'Search', {
      // TODO: What should 'content_*' represent for search tracking?
      // content_category,
      // content_ids,
      // contents,
      // currency,
      search_string: properties.query.keywords,
      // TODO: Assign a value to searches
      // value
    })
  }

  public trackViewCurationLtv({ id, properties }: Events.ViewCurationLtvEvent) {
    this.trackSingleCustom(id, 'ViewCurationLTV', {
      value: properties.value,
      currency: properties.currency,
      content_category: properties.content_category,
    })
  }

  public trackCuratedListViewed({ id, properties }: Events.CuratedListViewedEvent) {
    const contents = properties.items.map(this.getFacebookContentsFromSellableTrackingProperties)
    const contentIds = contents.map((content) => content.id)

    // Only track this conversion the first time a user views a curation.
    if (!properties.first_view) {
      return
    }

    this.trackSingle(id, 'ViewContent', {
      content_ids: contentIds,
      // TODO: What should the 'content_name' be for a curated list?
      // content_name: '',
      content_type: 'product',
      // @ts-ignore Facebook Pixel typings are incomplete
      contents: contents,
      currency: properties.currency,
      content_category: properties.content_category || undefined,
      // TODO: Will this artificially inflate the value of the user if we consider all curated items in the "value"?
      value: contents.reduce((sum, item) => {
        // TODO: 'item_price' is incorrectly typed as a string. parseInt can be removed when the typings are fixed.
        return sum + parseInt(item.item_price || '0', 10)
      }, 0),
    })
  }

  public trackPaymentInfoEntered({ id, properties }: Events.PaymentInfoEnteredEvent) {
    const contents = properties.products.map(this.getFacebookContentsFromSellableTrackingProperties)
    const contentIds = contents.map((content) => content.id)
    const contentCategory =
      properties.products.length > 0 ? properties.products[0].category : undefined

    this.trackSingle(id, 'AddPaymentInfo', {
      content_type: 'product',
      content_category: contentCategory,
      content_ids: contentIds,
      // @ts-ignore Facebook Pixel typings are incomplete
      contents: contents,
      currency: properties.currency,
      value: properties.value ? parseFloat(properties.value) : undefined,
    })
  }

  public trackExpertApplicationSubmitted({
    id,
    properties,
  }: Events.ExpertApplicationSubmittedEvent) {
    this.trackSingle(id, 'SubmitApplication', {
      content_category: properties.category,
    })
  }

  public trackUserRegistered({ id }: Events.UserRegisteredEvent, context: TrackingContext) {
    const { firstName, lastName } = context.traits
    const contentName = [firstName, lastName].join(' ')

    this.trackAfterTraits(id, 'CompleteRegistration', {
      content_name: contentName,
      // @ts-ignore Facebook Pixel typings are inaccurate
      status: true,
      // TODO: User-level currency tracking
      // currency: 'USD',
    })
  }

  public trackArticleViewed({ id, properties }: Events.ArticleViewedEvent) {
    this.trackSingle(id, 'ViewContent', {
      content_category: properties.article_department,
    })
  }

  public trackTopListViewed({ id, properties }: Events.TopListViewedEvent) {
    this.trackSingle(id, 'ViewContent', {
      content_category: properties.top_list_category,
    })
  }
}
