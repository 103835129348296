import Id from './base'

/**
 * A UserId represents the master identity of any registered Curated user.
 *
 * See: https://tracking.corp.curated.com/guide/sessionization.html#identification
 */
export default class UserId extends Id {
  public constructor(underlyingId: string) {
    super('userId', underlyingId)
  }
}
