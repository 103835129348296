import TrackingClient from '../TrackingClient'
import ansicolor from 'ansicolor'
import hashIndex from '@deal/hash-index'
import { Event, OptionalEventProperties } from '../events'
import { Identity, GenericTracker } from '../types/tracker'
import { UserTraits } from '../types/traits'

const COLORS = [
  ansicolor.bgLightRed.white.bright,
  ansicolor.bgLightGreen.white.bright,
  ansicolor.bgLightYellow.white.bright,
  ansicolor.bgLightBlue.white.bright,
  ansicolor.bgLightMagenta.white.bright,
  ansicolor.bgLightCyan.white.bright,
]

/**
 * Returns an array of arguments to be passed to `console.log`, `console.group`, etc.
 */
function colorizeTextForWebConsole(color: ansicolor, text: string) {
  return ansicolor.parse(color(text)).asChromeConsoleLogArguments
}

/**
 * Flatten the user traits for display in the console.
 */
function flattenUserTraits(traits: UserTraits) {
  let flattenedTraits: { [key: string]: any } = {}

  for (let [prop, value] of Object.entries(traits)) {
    if (prop === 'address') {
      flattenedTraits = {
        ...flattenedTraits,
        ...value,
      }
    } else {
      flattenedTraits[prop] = value
    }
  }

  return flattenedTraits
}

/**
 * Log events to the browser console.
 *
 *  - Events are colorized according to the event name
 *  - Event payloads are displayed in a table format along with the event timestamp
 */
export default class ConsoleTracker implements GenericTracker {
  private _client: TrackingClient

  public constructor(client: TrackingClient) {
    this._client = client
  }

  public track(event: Event<OptionalEventProperties>) {
    const eventDisplayName = event.displayName
    const eventData = Object.assign({ _id: event.id }, event.properties)
    const color = COLORS[hashIndex(eventDisplayName, COLORS.length)]

    console.groupCollapsed(...colorizeTextForWebConsole(color, ` ${eventDisplayName} `))
    console.log(...colorizeTextForWebConsole(ansicolor.bright, `${new Date()}`))
    if (eventData) {
      console.table(eventData)
    }
    console.groupEnd()
  }

  public identify(identity: Identity) {
    const color = COLORS[0]

    console.groupCollapsed(...colorizeTextForWebConsole(color, ` Identify `))
    console.log(...colorizeTextForWebConsole(ansicolor.bright, `${new Date()}`))
    console.table({
      ...identity,
      sessionId: this._client.sessionId,
      browserId: this._client.browserId,
    })
    console.groupEnd()
  }

  public touch() {
    const color = COLORS[0]
    const firstTouch = this._client.firstTouch!
    const lastTouch = this._client.lastTouch!

    console.groupCollapsed(...colorizeTextForWebConsole(color, ` Touch `))
    console.log(
      ...colorizeTextForWebConsole(
        ansicolor.bright,
        `First Touch Attribution (${firstTouch.timestamp})`
      )
    )
    console.table(firstTouch.attributes)
    console.log(
      ...colorizeTextForWebConsole(
        ansicolor.bright,
        `Last Touch Attribution (${lastTouch.timestamp})`
      )
    )
    console.table(lastTouch.attributes)
    console.groupEnd()
  }

  public addTraits(newTraits: UserTraits) {
    const color = COLORS[0]

    console.groupCollapsed(...colorizeTextForWebConsole(color, ` Traits `))
    console.log(...colorizeTextForWebConsole(ansicolor.bright, `New Traits`))
    console.table(flattenUserTraits(newTraits))
    console.log(...colorizeTextForWebConsole(ansicolor.bright, `All Traits`))
    console.table(flattenUserTraits(this._client.getTraits()))
    console.groupEnd()
  }

  public setDepartment(departmentSlug: string) {
    const color = COLORS[0]

    console.groupCollapsed(...colorizeTextForWebConsole(color, ` Department `))
    console.log(...colorizeTextForWebConsole(ansicolor.bright, `${new Date()}`))
    console.table({
      department: departmentSlug,
    })
    console.groupEnd()
  }

  public setPageKey(pageKey: string) {
    const color = COLORS[0]

    if (pageKey) {
      console.groupCollapsed(...colorizeTextForWebConsole(color, ` Page Key `))
      console.log(...colorizeTextForWebConsole(ansicolor.bright, `${new Date()}`))
      console.table({
        pageKey: pageKey,
      })
      console.groupEnd()
    } else {
      console.groupCollapsed(...colorizeTextForWebConsole(color, ` Page Key Unset `))
      console.log(...colorizeTextForWebConsole(ansicolor.bright, `${new Date()}`))
      console.groupEnd()
    }
  }

  public setConnectWidgetParentUrl(parentUrl?: string) {
    const color = COLORS[0]

    if (parentUrl) {
      console.groupCollapsed(...colorizeTextForWebConsole(color, ` Connect Widget Parent URL `))
      console.log(...colorizeTextForWebConsole(ansicolor.bright, `${new Date()}`))
      console.table({ parentUrl: parentUrl })
      console.groupEnd()
    } else {
      console.groupCollapsed(
        ...colorizeTextForWebConsole(color, ` Connect Widget Parent URL Unset `)
      )
      console.log(...colorizeTextForWebConsole(ansicolor.bright, `${new Date()}`))
      console.groupEnd()
    }
  }

  public reset() {
    const color = COLORS[0]

    console.groupCollapsed(...colorizeTextForWebConsole(color, ` Reset `))
    console.log(...colorizeTextForWebConsole(ansicolor.bright, `${new Date()}`))
    console.groupEnd()
  }

  public disable() {
    const color = COLORS[0]

    console.groupCollapsed(...colorizeTextForWebConsole(color, ` Tracking Disabled `))
    console.log(...colorizeTextForWebConsole(ansicolor.bright, `${new Date()}`))
    console.groupEnd()
  }

  public enable() {
    const color = COLORS[0]

    console.groupCollapsed(...colorizeTextForWebConsole(color, ` Tracking Enabled `))
    console.log(...colorizeTextForWebConsole(ansicolor.bright, `${new Date()}`))
    console.groupEnd()
  }
}
