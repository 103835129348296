import { Event } from '../Event'
import { ViewerContext } from '../../constants'

export interface TopListViewedEventProperties {
  top_list_id: string
  top_list_title: string
  top_list_category: string
}

export class TopListViewedEvent extends Event<TopListViewedEventProperties> {
  static displayName = 'Top List Viewed'

  static allowedViewerContexts = [ViewerContext.Consumer]
}
