import { Event } from '../Event'
import { ViewerContext } from '../../constants'
import { OrderProperties } from '../types/consumer'

export interface PaymentInfoEnteredEventProperties extends OrderProperties {
  checkout_id: string
  order_id: string
  payment_method: string
  payment_type: string
}

export class PaymentInfoEnteredEvent extends Event<PaymentInfoEnteredEventProperties> {
  static displayName = 'Payment Info Entered'

  static allowedViewerContexts = [ViewerContext.Consumer]
}
