import { TouchPointAttributes } from '../types/touches'
import { TrackingQueryParameter } from '../constants/index'

export default function acquisitionAttributesFromUrl(urlString: string): TouchPointAttributes {
  const url = new URL(urlString)
  const queryString = url.search
  const parameters = new URLSearchParams(queryString)
  const attributes: TouchPointAttributes = {}

  // Sort the query parameters by increasing priority (see comment below).
  const prioritizedParameters: Array<[string, string]> = []
  parameters.forEach((queryParamValue, queryParamKey) => {
    prioritizedParameters.push([queryParamKey, queryParamValue])
  })
  prioritizedParameters.sort(([queryParamKeyA], [queryParamKeyB]) => {
    const priorityA = priorityFromQueryParameter(queryParamKeyA)
    const priorityB = priorityFromQueryParameter(queryParamKeyB)

    return priorityA - priorityB
  })

  // Iterate over the [key, value] tuples, setting the acquisition attributes.
  prioritizedParameters.forEach(([queryParamKey, queryParamValue]) => {
    if (!(queryParamKey in TrackingQueryParameter)) {
      return
    }

    let attributeKey =
      QUERY_PARAMETERS_TO_ACQUISITION_ATTRIBUTES[queryParamKey as TrackingQueryParameter]

    if (Array.isArray(attributeKey)) {
      attributeKey = attributeKey[0]
    }

    if (attributeKey) {
      attributes[attributeKey] = queryParamValue
    }
  })

  // If an `e` parameter exists in the URL, or if the URL matches `/e/:expert-vanity`, set
  //   the source to "expert_affiliate" and include the referring expert's vanity ID
  let referringExpertVanityId = parameters.get('e')
  const expertAffiliateUrlMatch = url.pathname.match(/^\/e\/([^\/]+)\/?$/)
  if (expertAffiliateUrlMatch) {
    referringExpertVanityId = expertAffiliateUrlMatch[1]
  }

  if (referringExpertVanityId) {
    attributes.source = 'expert_affiliate'
    attributes.expertVanityId = referringExpertVanityId
  }

  // Unless specified otherwise, attribution is considered organic.
  if (!attributes.source) {
    attributes.source = 'organic'
  }

  return attributes
}

/**
 * A mapping of query paremeters to their corresponding touch point attributes.
 *
 * Multiple query parameters may map to the same attribute, so a priority may be specified
 *   by setting the value to a tuple of [attribute, priority]. Higher priorities take
 *   precedence during parsing. Attributes without a specified priority are considered to
 *   have a priority of 0.
 *
 * For example: "utm_source" (priority: 10) takes precedence over "c_source" (priority: 0)
 */
export const QUERY_PARAMETERS_TO_ACQUISITION_ATTRIBUTES: {
  [queryParam in TrackingQueryParameter]:
    | keyof TouchPointAttributes
    | [keyof TouchPointAttributes, number]
} = {
  ad_id: 'adId',
  ad_type: 'adType',
  ad_search_query: 'searchQuery',
  adgroup_id: 'adgroupId',
  expert_id: 'expertId',
  campaign_id: 'campaignId',
  department: 'department',
  gclid: 'googleClickId',
  fbclid: 'facebookClickId',
  fb_placement: ['placement', 10],
  fb_site_source_name: 'siteSourceName',
  product_id: 'productId',
  referrer_id: 'referrerId',
  target_id: 'targetId',
  c_adgroup: ['adgroup', 0],
  c_medium: ['medium', 0],
  c_campaign: ['campaign', 0],
  c_term: ['term', 0],
  c_content: ['content', 0],
  c_source: ['source', 0],
  c_id: ['campaignId', 0],
  utm_adgroup: ['adgroup', 10],
  utm_medium: ['medium', 10],
  utm_campaign: ['campaign', 10],
  utm_term: ['term', 10],
  utm_content: ['content', 10],
  utm_source: ['source', 10],
  utm_id: ['campaignId', 10],
  vt_match_type: 'matchType',
  vt_device: 'device',
  vt_device_model: 'deviceModel',
  vt_geo_location: 'geoLocation',
  vt_interest_location: 'interestLocation',
  vt_placement: ['placement', 0],
  vt_target_id: 'targetId',
  vt_ad_position: 'adPosition',
  vt_network: 'network',
}

/**
 * Given a query parameter, return the priority from the mapping above.
 */
function priorityFromQueryParameter(queryParam: string) {
  if (!(queryParam in TrackingQueryParameter)) {
    return 0
  }

  const attribute = QUERY_PARAMETERS_TO_ACQUISITION_ATTRIBUTES[queryParam as TrackingQueryParameter]

  if (Array.isArray(attribute)) {
    return attribute[1]
  }

  return 0
}

export const ACQUISITION_QUERY_PARAMETERS = Object.keys(QUERY_PARAMETERS_TO_ACQUISITION_ATTRIBUTES)
