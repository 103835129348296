import { Event } from '../Event'
import { ViewerContext } from '../../constants'
import { SellableProperties } from '../types/consumer'

export interface ProductAddedToWishlistEventProperties extends SellableProperties {}

export class ProductAddedToWishlistEvent extends Event<ProductAddedToWishlistEventProperties> {
  static displayName = 'Product Added To Wishlist'

  static allowedViewerContexts = [ViewerContext.Consumer]
}
