import { Event } from '../Event'
import { ViewerContext } from '../../constants'

export interface UserRegisteredEventProperties {
  user_id: string
  path_flow_id?: string
  path_graph_flow_id?: string
  context:
    | 'register'
    | 'path-complete'
    | 'path-create'
    | 'sms-modal'
    | 'sign-in-modal'
    | 'guest-checkout'
    | 'prefill-sms-modal'
    | 'guest-email-modal'
    | 'expert-affiliate-landing'
    | 'expert-question-modal'
    | 'for-you-feed'
    | 'expertise-save-matches'
}

export class UserRegisteredEvent extends Event<UserRegisteredEventProperties> {
  static displayName = 'User Registered'

  static allowedViewerContexts = [ViewerContext.Consumer]
}
