import { Event } from '../Event'
import { ViewerContext } from '../../constants'

export interface LeadCreatedEventProperties {
  lead_id: string
  department_slug: string
  category_slug: string
  marketable_category_slug?: string
  marketing_qualified?: boolean
}

export class LeadCreatedEvent extends Event<LeadCreatedEventProperties> {
  static displayName = 'Lead Created'

  static allowedViewerContexts = [ViewerContext.Consumer]
}
