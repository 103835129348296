import { Event } from '../Event'
import { ViewerContext } from '../../constants'

export interface PageViewedEventProperties {
  navigation_type: 'navigate' | 'reload' | 'client_push' | 'client_pop' | 'client_replace'
  ref?: string
}

export class PageViewedEvent extends Event<PageViewedEventProperties> {
  static displayName = 'Page Viewed'

  static allowedViewerContexts = [
    ViewerContext.Consumer,
    ViewerContext.BusinessUser,
    ViewerContext.PartnerUser,
    ViewerContext.Operator,
  ]
}
