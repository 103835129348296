import type { Options, SegmentEvent, JSONObject } from '@segment/analytics-next'
import type { MiddlewareParams } from '@segment/analytics-next/dist/pkg/plugins/middleware'
import TrackingClient from '../TrackingClient'
import { Identity, TrackingContext, GenericTracker } from '../types/tracker'
import { TouchPointAttributes } from '../types/touches'
import { ViewerContext } from '../constants'
import { Event, OptionalEventProperties } from '../events/Event'
import * as Events from '../events'
import uuidv4 from 'uuid/v4'

export default class SegmentTracker implements GenericTracker {
  private client: TrackingClient

  public constructor(client: TrackingClient) {
    if (!window.analytics) {
      throw new Error(
        'The Segment snippet is missing, but must be installed to use the SegmentTracker.'
      )
    }

    this.client = client

    this.segmentAnalytics.ready(() => {
      if (this.client.sessionId) {
        this.segmentAnalytics.setAnonymousId(this.client.sessionId)
      }
    })

    this.segmentAnalytics.addSourceMiddleware(this.transformMessageIdMiddleware)
  }

  private transformMessageIdMiddleware({ payload, next }: MiddlewareParams) {
    if (payload.obj.context?.eventId) {
      payload.obj.messageId = payload.obj.context.eventId
    }

    next(payload)
  }

  /**
   * Resolve window.analytics.
   *
   * This getter exists solely to simplify typechecking. We can be certain
   *   `window.analytics` is defined since we check for it in the constructor.
   */
  private get segmentAnalytics() {
    return window.analytics!
  }

  /**
   * Handle events as they are tracked.
   *
   * Segment treats page views as special events, so we forward events to
   *   either SegmentTracker.track or SegmentTracker.page as appropriate.
   */
  public track(event: Event<OptionalEventProperties>, context: TrackingContext) {
    if (event instanceof Events.PageViewedEvent) {
      this.trackSegmentPage(event, context)
    } else {
      this.trackSegmentEvent(event.id, event.displayName, context, event.properties as JSONObject)
    }
  }

  /**
   * Associate a tracked user with a persistent User ID.
   *
   * @param identity
   * @param context
   */
  public identify(identity: Identity, context: TrackingContext) {
    const options = this.getSegmentTrackingOptions(context)

    this.segmentAnalytics.ready(() => {
      this.segmentAnalytics.setAnonymousId(context.sessionId)
      this.segmentAnalytics.identify(identity.userId, context.traits, options)
    })
  }

  /**
   * Disassociate the tracked user from the previously identified user ID, and
   *   clear any other user-specific state/traits.
   */
  public reset() {
    this.segmentAnalytics.ready(() => {
      this.segmentAnalytics.reset()
    })
  }

  /**
   * A wrapper for the Segment Analytics.js `track` method. We only accept a
   *   single signature, rather than the overloaded signatures Segment allows.
   *
   * See: https://segment.com/docs/sources/website/analytics.js/#track
   *
   * @param eventDisplayName e.g. "Product Added", as opposed to "productAdded"
   * @param properties A bag of data to send along with this event.
   */
  private trackSegmentEvent(
    eventId: string,
    eventDisplayName: string,
    context: TrackingContext,
    properties?: SegmentEvent['properties']
  ) {
    const options = this.getSegmentTrackingOptions(context)

    options.context.eventId = eventId

    this.segmentAnalytics.ready(() => {
      this.segmentAnalytics.track(eventDisplayName, properties || {}, options)
    })
  }

  /**
   * A wrapper for the Segment Analytics.js `page` method. We only accept a
   *   single signature, rather than the overloaded signatures Segment allows.
   *
   * See: https://segment.com/docs/sources/website/analytics.js/#page
   *
   * @param properties A bag of data to send along with this event.
   */
  private trackSegmentPage(event: Events.PageViewedEvent, context: TrackingContext) {
    const options = this.getSegmentTrackingOptions(context)

    this.segmentAnalytics.ready(() => {
      this.segmentAnalytics.page(event.properties || {}, options)
    })
  }

  /**
   * Mark the start of a new session and associate it with marketing/acquisition data
   *
   * See: https://dealdotcom.atlassian.net/wiki/spaces/ENGINEERING/pages/13467664/Sessionization
   *
   * @param attributes Acquisition attributes associated with this touchpoint.
   */
  public touch(attributes: TouchPointAttributes, context: TrackingContext) {
    const touchId = uuidv4()

    this.trackSegmentEvent(touchId, 'Touch', context, attributes as JSONObject)
  }

  /**
   * Attach some additional context to every Segment call. We override Segment's
   *   default `anonymousId` to use our `sessionId`.
   *
   * We also send last-touch attribution data with every tracking event, however
   *   last-touch attribution does not make sense for "initAppUrl" so we
   *   use first-touch for that attribute.
   *
   * Attribution data should be derived by our data warehouse rather than being
   *   tracked on the frontend. Sending this data along with individual tracking
   *   events is a bad practice and will be deprecated/removed eventually.
   */
  private getSegmentTrackingOptions(
    context: TrackingContext
  ): Options & { context: Exclude<Options['context'], undefined> } {
    const firstTouchAttributes = context.attribution.firstTouch?.attributes || {}
    const lastTouchAttributes = context.attribution.lastTouch?.attributes || {}

    // N.B.: This is a confusing name: in this repo, "traits" refers to PII like
    //   "firstName" and "lastName" (see`src/traits`). Here, "traits" means user
    //   acquisition/attribution data. This is due to historical reasons and may
    //   change in the future.
    const attributionTraits = {
      ...lastTouchAttributes,
      initAppUrl: firstTouchAttributes.initAppUrl,
    }

    // We also send as many canonically supported Segment traits with each event.
    //   See: https://segment.com/docs/connections/spec/identify/#traits
    const traits = {
      ...attributionTraits,
      address: context.traits.address,
      birthday: context.traits.dateOfBirth,
      email: context.traits.email,
      firstName: context.traits.firstName,
      gender: context.traits.gender,
      lastName: context.traits.lastName,
      phone: context.traits.phone,
    }

    return {
      anonymousId: context.sessionId,
      context: {
        // Segment does not lowercase the (userId || anonymousId) that they use as the
        //   `external_id` in their Facebook Conversions API destination, which means
        //   the external IDs do not match with our direct browser-side Facebook
        //   integration. We have the option to tell Segment to use a different field
        //   for the external ID, so we are adding this field as a temporary measure
        //   to use until Segment fixes their integration. No other pipelines should
        //   use this field.
        externalId: (context.userId || context.sessionId).toLowerCase(),
        consumerId: context.consumerId,
        businessUserId: context.businessUserId,
        partnerUserId: context.partnerUserId,
        operatorId: context.operatorId,
        tenantId: context.tenantId,
        publisherId: context.publisherId,
        viewerContext: this.getHumanReadableUserViewerContext(context.viewerContext),
        application: context.application,
        department: context.department,
        pageKey: context.pageKey,
        // @ts-ignore We use context.traits to send additional traits, but the only
        //  officially supported field for this object is "crossDomainId".
        traits,
      },
    }
  }

  private getHumanReadableUserViewerContext(viewerContext: ViewerContext): string {
    switch (viewerContext) {
      case ViewerContext.Consumer:
        return 'consumer'
      case ViewerContext.BusinessUser:
        return 'business_user'
      case ViewerContext.PartnerUser:
        return 'partner_user'
      case ViewerContext.Operator:
        return 'operator'
    }
  }
}
