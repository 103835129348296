import { GenericTracker, Identity } from '../types/tracker'
import { UserTraits } from '../types/traits'
import { Event, OptionalEventProperties } from '../events'
import { TouchPointAttributes, TrackingContext } from '../index'

export default class HeapTracker implements GenericTracker {
  public constructor() {
    if (!window.heap) {
      throw new Error(
        'The Heap.js snippet is missing, but must be installed to use the HeapTracker.'
      )
    }
  }

  private get heap() {
    return window.heap!
  }

  public track(event: Event<OptionalEventProperties>, context: TrackingContext) {
    // Heap does not support nested properties, so we flatten them to a single level. Nested properties
    //   will appear in Heap using "dot notation", e.g. "query.keywords"
    const flattenedProperties = event.properties
      ? flattenObject(event.properties as JSONObject)
      : {}

    this.heap.track(event.displayName, {
      ...flattenedProperties,
      pageKey: context.pageKey,
    })
  }

  public touch(attributes: TouchPointAttributes, context: TrackingContext) {
    this.heap.track('Touch', {
      ...attributes,
      pageKey: context.pageKey,
    })
  }

  public identify(identity: Identity) {
    this.heap.identify(identity.userId)
  }

  public addTraits(traits: UserTraits) {
    const allowedTraits = {
      ...traits,
      city: traits.address?.city,
      state: traits.address?.state,
      country: traits.address?.country,
      postalCode: traits.address?.postalCode,
    }

    // Delete some PII
    delete allowedTraits.phone
    delete allowedTraits.email
    delete allowedTraits.lastName
    delete allowedTraits.address

    this.heap.addUserProperties(allowedTraits)
  }

  public reset() {
    this.heap.resetIdentity()
  }
}

/**
 * Flatten an object to a single level, using the "dot notation" path as the key.
 *
 * Note: This only handles nested objects and string values. Values of other primitive types
 *  (array, null, number, and boolean) are left untouched. Heap may ignore these.
 *
 * e.g. here the "b" value is flatted, but the "d" value is ignored because is it an array:
 *
 *   { a: { b: "1" }, c: "2", d: [{ e: "3"}] }
 *
 *   =>
 *
 *   { "a.b": "1", "c": 2, "d": [{ e: "3"}] }
 *
 * Source: https://www.w3resource.com/javascript-exercises/fundamental/javascript-fundamental-exercise-233.php
 */
type JSONPrimitive = string | number | boolean | null
type JSONValue = JSONPrimitive | JSONObject | JSONArray
type JSONArray = Array<JSONValue>
type JSONObject = {
  [member: string]: JSONValue
}
function flattenObject(obj: JSONObject, prefix: string = '') {
  return Object.keys(obj).reduce<{ [key: string]: JSONPrimitive | JSONArray }>((acc, k) => {
    const pre = prefix.length ? prefix + '.' : ''

    const val = obj[k]

    if (typeof val === 'object' && !Array.isArray(val) && val !== null) {
      Object.assign(acc, flattenObject(val, pre + k))
    } else {
      acc[pre + k] = val
    }

    return acc
  }, {})
}
