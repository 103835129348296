import { Event } from '../Event'
import { ViewerContext } from '../../constants'

export interface ProductListViewedEventProperties {
  list_id: string
  sellable_ids?: string[]
  source_module?: string
}

export class ProductListViewedEvent extends Event<ProductListViewedEventProperties> {
  static displayName = 'Product List Viewed'

  static allowedViewerContexts = [ViewerContext.Consumer, ViewerContext.BusinessUser]
}
