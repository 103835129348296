import { Event } from '../Event'
import { ViewerContext } from '../../constants'

export interface PageNotFoundEventProperties {
  url: string
  referrer_url?: string
}

export class PageNotFoundEvent extends Event<PageNotFoundEventProperties> {
  static displayName = 'Page Not Found'

  static allowedViewerContexts = [
    ViewerContext.Consumer,
    ViewerContext.BusinessUser,
    ViewerContext.PartnerUser,
    ViewerContext.Operator,
  ]
}
