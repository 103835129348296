import { Event } from '../Event'
import { ViewerContext } from '../../constants'

export interface RichCardViewedEventProperties {
  rich_card_type: string
  expert_id: string
}

export class RichCardViewedEvent extends Event<RichCardViewedEventProperties> {
  static displayName = 'Rich Card Viewed'

  static allowedViewerContexts = [ViewerContext.Consumer]
}
