import store from '../store'

export default class Department {
  private _department?: string

  public constructor() {
    const existingDepartmentSlug = this.load()

    if (existingDepartmentSlug) {
      this.set(existingDepartmentSlug)
    }
  }

  private load() {
    return (store.get('department') as string) || undefined
  }

  public set(departmentSlug: string) {
    this._department = departmentSlug

    store.set('department', departmentSlug)
  }

  public get() {
    return this._department
  }

  public reset() {
    this._department = undefined

    store.remove('department')
  }
}
