import { Event } from '../Event'
import { ViewerContext } from '../../constants'
import { SellableProperties } from '../types/consumer'

export interface ProductClickedEventProperties extends SellableProperties {
  list_id?: string
  position?: number
  entry_point?: string
}

export class ProductClickedEvent extends Event<ProductClickedEventProperties> {
  static displayName = 'Product Clicked'

  static allowedViewerContexts = [ViewerContext.Consumer, ViewerContext.BusinessUser]
}
