import { Event } from '../Event'
import { ViewerContext } from '../../constants'

export type ChatWidgetStateType = 'minimized' | 'preview' | 'expanded'

export interface ChatWidgetStateChangedProperties {
  previous_state: ChatWidgetStateType
  new_state: ChatWidgetStateType
  trigger: string
}

export class ChatWidgetStateChanged extends Event<ChatWidgetStateChangedProperties> {
  static displayName = 'Chat Widget State Changed'

  static allowedViewerContexts = [ViewerContext.Consumer]
}
