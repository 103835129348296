import store from '../store'
import uuidv4 from 'uuid/v4'

export default class Id {
  private _type: string
  private _id!: string

  public constructor(type: string, underlyingId?: string) {
    this._type = type
    this.set(underlyingId || this.load() || this.mint())
  }

  /**
   * Set the string representation of this ID.
   *
   * Note: IDs are double-written to memory and localStorage for performance. If we
   *   didn't keep the ID in memory we'd incur the non-trivial cost of frequent
   *   localStorage lookups.
   *
   * See: https://stackoverflow.com/questions/8074218/speed-cost-of-localstorage
   */
  public set(id: string) {
    this._id = id
    store.set(this._type, this._id)
  }

  /**
   * Get the string representation of this ID.
   *
   * This is an alias of `toString`, but intended to be used internally.
   */
  private get() {
    return this._id
  }

  /**
   * Get the string respresentation of this ID.
   */
  public toString = this.get.bind(this)

  /**
   * Generate a new universally-unique identifier.
   */
  private mint() {
    return uuidv4()
  }

  /**
   * Fetch the identifier from localStorage. Used during initialization to ensure
   *   identifiers are persisted across page reloads.
   */
  private load() {
    return store.get(this._type)
  }

  /**
   * Clear the identifier from local storage.
   */
  public reset() {
    store.remove(this._type)
  }
}
