import Id from './base'

/**
 * A Session ID is minted by our API when a user first visits our website. It is persisted
 *   in a never-expiring cookie, and is only explicitly reset when a user logs out.
 *
 * Each app must provide this session ID in the `.configure()` call immediately after loading
 *   the tracking snippet.
 *
 * See: https://tracking.corp.curated.com/guide/sessionization.html#identification
 * And: https://tracking.corp.curated.com/guide/sessionization.html#overview
 */
export default class SessionId extends Id {
  public constructor(underlyingId: string) {
    super('sessionId', underlyingId)
  }
}
