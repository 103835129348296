import { Event } from '../Event'
import { ViewerContext } from '../../constants'
import { SearchQuery, SearchEntryPoint } from '../types/consumer'

export interface ProductsSearchResultsViewedEventProperties {
  query: SearchQuery
  total_results_count: number
  visible_results_count: number
  entry_point?: SearchEntryPoint
}

export class ProductsSearchResultsViewedEvent extends Event<ProductsSearchResultsViewedEventProperties> {
  static displayName = 'Products Search Results Viewed'

  static allowedViewerContexts = [ViewerContext.Consumer]
}
