// Borrowed from: https://stackoverflow.com/a/52551910
//
// Note that this does not handle all edge cases. We use this for converting
//   event display names into tracker method names, so the cases it doesn't
//   handle are acceptable for us (strings already in PascalCase, strings with
//   special characters, etc.)
export default function pascalCase(str: string) {
  return (' ' + str).toLowerCase().replace(/[^a-zA-Z0-9]+(.)/g, function (_match, char) {
    return char.toUpperCase()
  })
}
