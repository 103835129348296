import Id from './base'

/**
 * A BusinessUserId represents a user with access to the business app (i.e. an expert)
 *
 * See: https://tracking.corp.curated.com/guide/sessionization.html#identification
 */
export default class BusinessUserId extends Id {
  public constructor(underlyingId: string) {
    super('businessUserId', underlyingId)
  }
}
