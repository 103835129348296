import { SpecificTracker, Identity } from '../types/tracker'
import { UserTraits } from '../types/traits'
import { TrackingClient } from '../index'
import * as Events from '../events'

export default class ClarityTracker implements SpecificTracker {
  private _client: TrackingClient

  public constructor(client: TrackingClient) {
    this._client = client

    if (!window.clarity) {
      throw new Error(
        'The Clarity snippet is missing, but must be installed to use the ClarityTracker.'
      )
    }

    // Set the initial identity based on whatever information is available during initialization (usually
    //   only User ID and Session ID)
    this.identifyClarityUser()
  }

  private get clarity() {
    return window.clarity!
  }

  // Identify the user based on the current state of the TrackingClient, optionally setting a specific
  //   user ID or updated user traits. Clarity hashes the different custom identifiers and friendly name
  //   before storing them on the server. These are not exposed in the dashboard, but are searchable.
  //
  //
  // See: https://learn.microsoft.com/en-us/clarity/setup-and-installation/identify-api#storing-and-managing-custom-ids
  private identifyClarityUser(
    customUserId = this._client.userId,
    customPageKey = this._client.getPageKey(),
    traits = this._client.getTraits()
  ) {
    const customSessionId = this._client.sessionId
    const friendlyName = [traits.firstName, traits.lastName].join(' ').trim() || undefined

    // Clarity does not support setting a custom session ID, pageKey, or friendly name without
    //   also setting a custom user ID. They do not recommend using the identify API for guest
    //   users.
    if (!customUserId) {
      return
    }

    this.clarity('identify', customUserId, customSessionId, customPageKey, friendlyName)
  }

  public identify(identity: Identity) {
    // Update the identity to include the new User ID
    this.identifyClarityUser(identity.userId)
  }

  public setPageKey(pageKey: string) {
    // Update the identity to include the new page key
    this.identifyClarityUser(undefined, pageKey)
  }

  public addTraits(traits: UserTraits) {
    // Update the identity to include a friendly name based on the new traits
    this.identifyClarityUser(undefined, undefined, traits)
  }

  public trackExperimentViewed({ properties }: Events.ExperimentViewedEvent) {
    this.clarity('set', `experiment-${properties.experiment}`, properties.variation)
  }
}
