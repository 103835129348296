import { Event } from '../Event'
import { ViewerContext } from '../../constants'

export interface ExpertApplicationSubmittedEventProperties {
  category?: string
  user_id?: string
}

export class ExpertApplicationSubmittedEvent extends Event<
  ExpertApplicationSubmittedEventProperties
> {
  static displayName = 'Expert Application Submitted'

  static allowedViewerContexts = [ViewerContext.Consumer]
}
