import { Event } from '../Event'
import { ViewerContext } from '../../constants'

export interface ArticleViewedEventProperties {
  article_id: string
  revision_id: string
  article_title: string
  article_categories: string[]
  article_department?: string
}

export class ArticleViewedEvent extends Event<ArticleViewedEventProperties> {
  static displayName = 'Article Viewed'

  static allowedViewerContexts = [ViewerContext.Consumer]
}
