import BrowserId from './identification/browserId'
import SessionId from './identification/sessionId'
import UserId from './identification/userId'
import ConsumerId from './identification/consumerId'
import BusinessUserId from './identification/businessUserId'
import PartnerUserId from './identification/partnerUserId'
import OperatorId from './identification/operatorId'
import AttributionModel from './attribution'
import Traits from './traits'
import Department from './department'
import {
  Event,
  OptionalEventProperties,
  PageViewedEvent,
  PageViewedEventProperties,
  ExperimentMetricMeasuredEvent,
} from './events'
import { Application } from './types/applications'
import { UserTraits } from './types/traits'
import { TouchPointAttributes } from './types/touches'
import { ViewerContext } from './constants'
import {
  Identity,
  GenericTracker,
  SpecificTracker,
  TrackingContext,
  TrackingEventMethodName,
  TrackingEventName,
} from './types/tracker'
import acquisitionAttributesFromUrl from './util/acquisitionAttributesFromUrl'
import displayNameForViewerContext from './util/displayNameForViewerContext'
import pascalCase from './util/pascalCase'
import store from './store'

import AdjustTracker from './trackers/AdjustTracker'
import BingTracker from './trackers/BingTracker'
import ClarityTracker from './trackers/ClarityTracker'
import ConsoleTracker from './trackers/ConsoleTracker'
import FacebookTracker from './trackers/FacebookTracker'
import GoogleTracker from './trackers/GoogleTracker'
import HeapTracker from './trackers/HeapTracker'
import RedditAdsTracker from './trackers/RedditAdsTracker'
import RudderstackTracker from './trackers/RudderstackTracker'
import SegmentTracker from './trackers/SegmentTracker'

// This is the bag of "exports" exposed to the .configure() callback.
const ConfigurationContext = {
  AdjustTracker,
  BingTracker,
  ClarityTracker,
  ConsoleTracker,
  FacebookTracker,
  GoogleTracker,
  HeapTracker,
  RedditAdsTracker,
  RudderstackTracker,
  SegmentTracker,
  ViewerContext,
}

export type TrackerFactory = (client: TrackingClient) => GenericTracker | SpecificTracker

export type TrackingClientOptions = {
  application: Application
  trackers: TrackerFactory[]
  sessionId: string
  tenantId?: string
  publisherId?: string
  viewerContext: ViewerContext
  additionalContext?: () => Record<string, any>
}
export default class TrackingClient {
  private _application?: Application

  private _trackers: (GenericTracker | SpecificTracker)[]

  private _viewerContext: ViewerContext

  private _disabled: boolean

  private _ccpaConsent: boolean | null

  private _tenantId?: string
  private _publisherId?: string

  private _browserId: BrowserId
  private _sessionId?: SessionId
  private _userId?: UserId
  private _consumerId?: ConsumerId
  private _businessUserId?: BusinessUserId
  private _partnerUserId?: PartnerUserId
  private _operatorId?: OperatorId

  private _traits: Traits

  private _attribution: AttributionModel

  private _department: Department

  private _pageKey?: string

  private _parentUrl?: string
  private _additionalContext?: () => Record<string, any>

  public constructor() {
    this._disabled = false

    this._ccpaConsent = store.get('ccpaConsent') ?? null

    this._viewerContext = store.get('viewerContext') || ViewerContext.Consumer

    // Initialize identifiers
    this._browserId = new BrowserId()

    // Initialize user traits
    this._traits = new Traits()

    // Initialize the user's department
    this._department = new Department()

    // Initialize attribution models
    this._attribution = new AttributionModel()

    // Initialize the trackers
    this._trackers = []
  }

  public get application() {
    return this._application
  }

  public get browserId() {
    return this._browserId.toString()
  }

  public get sessionId() {
    return this._sessionId?.toString()
  }

  public get tenantId() {
    return this._tenantId?.toString()
  }

  public get publisherId() {
    return this._publisherId?.toString()
  }

  public get userId() {
    return this._userId?.toString()
  }

  public get consumerId() {
    return this._consumerId?.toString()
  }

  public get businessUserId() {
    return this._businessUserId?.toString()
  }

  public get partnerUserId() {
    return this._partnerUserId?.toString()
  }

  public get operatorId() {
    return this._operatorId?.toString()
  }

  public get firstTouch() {
    return this._attribution.firstTouch
  }

  public get lastTouch() {
    return this._attribution.lastTouch
  }

  public get authenticated() {
    return this._viewerContext !== ViewerContext.Consumer
  }

  public get viewerContext() {
    return this._viewerContext
  }

  public get disabled() {
    return this._disabled
  }

  public configure(
    configurationFn: (context: typeof ConfigurationContext) => TrackingClientOptions
  ) {
    const options = configurationFn(ConfigurationContext)

    // Set session ID
    if (this._sessionId) {
      this._sessionId.set(options.sessionId)
    } else {
      this._sessionId = new SessionId(options.sessionId)
    }

    // Set the starting tenant/publisher IDs. These can be updated with subsequent calls to `identify`.
    this._tenantId = options.tenantId
    this._publisherId = options.publisherId

    // Set application context
    this._application = options.application

    // Set callback get additional context.
    this._additionalContext = options.additionalContext

    // Set viewer context
    this._viewerContext = options.viewerContext
    store.set('viewerContext', this._viewerContext)

    // Create and register each tracker
    options.trackers.forEach((trackerFactory) => {
      const tracker = trackerFactory(this)

      this._trackers.push(tracker)
    })
  }

  /**
   * Invoke a callback once the tracking snippet has been loaded and the client configured
   */
  public ready(callbackFn: () => void) {
    callbackFn()
  }

  /**
   * Generate a snapshot of the current state of the tracked user. This context is attached
   *   to each individual tracking event as well as calls to `identify` and `touch`.
   *
   * Trackers could derive this data directly from the TrackingClient, but this method allows
   *   us to ensure that the data associated with each event is accurate to the exact moment
   *   the event was instantiated (or when `identify`/`touch` was called), vs when the event
   *   was processed by the Tracker.
   *
   * To ensure that Tracker implementations aren't susceptible to race conditions, Trackers
   *   shouldn't access the `TrackingClient` directly (including this method), and should
   *   instead rely only on the context passed along with each method call.
   */
  public getTrackingContext(): TrackingContext {
    return {
      ...this._additionalContext?.(),
      browserId: this.browserId,
      sessionId: this.sessionId!,
      userId: this.userId,
      consumerId: this.consumerId,
      businessUserId: this.businessUserId,
      partnerUserId: this.partnerUserId,
      operatorId: this.operatorId,

      application: this.application!,

      tenantId: this.tenantId,
      publisherId: this.publisherId,
      viewerContext: this.viewerContext,

      attribution: {
        lastTouch: this.lastTouch ? Object.assign({}, this.lastTouch) : undefined,
        firstTouch: this.firstTouch ? Object.assign({}, this.firstTouch) : undefined,
      },

      traits: Object.assign({}, this.getTraits()),

      pageKey: this.getPageKey(),

      department: this.getDepartment(),

      connectWidgetParentUrl: this.getConnectWidgetParentUrl(),
    }
  }

  /**
   * Track an event.
   */
  public track(event: Event<OptionalEventProperties>) {
    if (this._disabled) {
      return
    }

    if (!event.allowedViewerContexts.includes(this.viewerContext)) {
      console.warn(
        `Can not track "${event.displayName}" as a ${displayNameForViewerContext(
          this.viewerContext
        )}. Allowed identities for this event are: ${event.allowedViewerContexts
          .map((viewerContext) => `"${displayNameForViewerContext(viewerContext)}"`)
          .join(', ')}`
      )
      return
    }

    const trackingContext = this.getTrackingContext()

    this._trackers.forEach((tracker) => {
      // If this tracker implements `GenericTracker`, call the `track` method.
      if ('track' in tracker) {
        tracker.track(event, trackingContext)
      }

      // If this tracker implements `SpecificTracker`, call the specific `trackXYZ` method.
      const specificMethodName = `track${pascalCase(
        event.displayName
      )}` as TrackingEventMethodName<TrackingEventName>

      if (specificMethodName in tracker) {
        // @ts-ignore
        ;(tracker as SpecificTracker)[specificMethodName]!(event, trackingContext)
      }
    })
  }

  /**
   * Track a page view event.
   *
   * This is an alias for `track(new PageViewedEvent())`. The only reason this method exists is
   *   so the method can be stubbed and called without importing the PageViewedEvent class (e.g.
   *   to track a page view in the <head>, before the full bundled app has loaded).
   */
  public page(properties: PageViewedEventProperties) {
    if (this._disabled) {
      return
    }

    return this.track(new PageViewedEvent(properties))
  }

  /**
   * Mark the start of a new attribution session and associate it with acquisition data.
   *
   * This method attempts to achieve a level of idempotency. This method is a no-op if either
   *   of the following are true:
   *
   *  - The window was loaded by reloading/refreshing the page.
   *  - The window's referrer has the same host as the active URL (i.e. the user is not coming
   *      from an external host).
   *
   * See: https://dealdotcom.atlassian.net/wiki/spaces/ENGINEERING/pages/13467664/Sessionization
   */
  public touch(href: string, referrer: string) {
    if (this._disabled) {
      return
    }

    const touchUrl = new URL(href)
    const referrerUrl = referrer.length > 0 ? new URL(referrer) : undefined

    const attributes: TouchPointAttributes = {
      initAppUrl: href,
      referrerUrl: referrer,
      ...acquisitionAttributesFromUrl(href),
    }

    // N.B.: The `window.performance.navigation` API is deprecated in favor of the Navigation
    //   Timing Level 2 specification, but the newer specification has poor support currently.
    //   Notably, Safari is missing support for the newer API. For now, this works well.
    const isPageRefresh =
      window.performance &&
      window.performance.navigation.type === window.performance.navigation.TYPE_RELOAD

    // N.B.: `initAppUrl` === `window.location.href` and `referrerUrl` === `window.document.referrer`
    //   (assuming the attributes are populated using the utils exported by this library)
    const isReferredBySameHost = touchUrl.host === referrerUrl?.host

    // Ignore invalid touch points
    if (isPageRefresh || isReferredBySameHost) {
      return
    }

    this._attribution.touch(attributes)

    this._trackers.forEach((tracker) => {
      if (tracker.touch) {
        tracker.touch(attributes, this.getTrackingContext())
      }
    })
  }

  /**
   * Track a metric for experimentation reporting. Metrics may or may not have a value associated with
   *    them. When reporting experiment results, metrics that have a value (e.g. "order_revenue") may
   *    be aggregated in different ways: average, sum, etc. Metrics that do not have a value (e.g.
   *    "product_details_page_views") can only be reported as counts (or counts per user).
   *
   * Use `trackBulkExperimentMetrics` if tracking multiple metrics simultaneously.
   */
  public trackExperimentMetric(key: string, value?: number) {
    if (this._disabled) {
      return
    }

    return this.track(
      new ExperimentMetricMeasuredEvent({
        metrics: [
          {
            key,
            value,
          },
        ],
      })
    )
  }

  /**
   * Track metrics for experimentation reporting. Metrics may or may not have a value associated with
   *    them. When reporting experiment results, metrics that have a value (e.g. "order_revenue") may
   *    be aggregated in different ways: average, sum, etc. Metrics that do not have a value (e.g.
   *    "product_details_page_views") can only be reported as counts (or counts per user).
   *
   * Use `trackExperimentMetric` if only tracking a single metric.
   */
  public trackBulkExperimentMetrics(metrics: Array<{ key: string; value?: number }>) {
    if (this._disabled) {
      return
    }

    return this.track(
      new ExperimentMetricMeasuredEvent({
        metrics,
      })
    )
  }

  /**
   * Set the {User/Consumer/BusinessUser/PartnerUser/Operator}Ids, TenantId and viewer context for the tracked user.
   */
  public identify(identity: Identity, viewerContext: ViewerContext) {
    const { userId, consumerId, businessUserId, partnerUserId, operatorId, tenantId, publisherId } =
      identity

    if (this._disabled) {
      return
    }

    this._viewerContext = viewerContext
    store.set('viewerContext', this._viewerContext)

    // Update TenantId
    if (tenantId !== undefined) {
      this._tenantId = tenantId
    }
    // Update PublisherId
    if (publisherId !== undefined) {
      this._publisherId = publisherId
    }

    // Update UserID
    if (this._userId) {
      this._userId.set(userId)
    } else {
      this._userId = new UserId(userId)
    }

    // Update ConsumerId
    if (consumerId) {
      if (this._consumerId) {
        this._consumerId.set(consumerId)
      } else {
        this._consumerId = new ConsumerId(consumerId)
      }
    } else {
      delete this._consumerId
    }

    // Update BusinessUserId
    if (businessUserId) {
      if (this._businessUserId) {
        this._businessUserId.set(businessUserId)
      } else {
        this._businessUserId = new BusinessUserId(businessUserId)
      }
    } else {
      delete this._businessUserId
    }

    // Update PartnerUserId
    if (partnerUserId) {
      if (this._partnerUserId) {
        this._partnerUserId.set(partnerUserId)
      } else {
        this._partnerUserId = new PartnerUserId(partnerUserId)
      }
    } else {
      delete this._partnerUserId
    }

    // Update OperatorId
    if (operatorId) {
      if (this._operatorId) {
        this._operatorId.set(operatorId)
      } else {
        this._operatorId = new OperatorId(operatorId)
      }
    } else {
      delete this._operatorId
    }

    this._trackers.forEach((tracker) => {
      if (tracker.identify) {
        tracker.identify(identity, this.getTrackingContext())
      }
    })
  }

  /**
   * Add traits (PII) to the tracked user.
   *
   * Traits are overwritten in place.
   */
  public addTraits(traits: UserTraits) {
    if (this._disabled) {
      return
    }

    if (traits === undefined) {
      return this._traits
    }

    this._traits.add(traits)

    // Allow individual trackers to add traits to their internal state
    this._trackers.forEach((tracker) => {
      if (tracker.addTraits) {
        tracker.addTraits(traits)
      }
    })
  }

  public getTraits() {
    return this._traits.get()
  }

  /**
   * Set the department for the tracked user (by a department slug)
   *
   * Note: The definition of a user's department is loose. Consumers can shop across
   *   departments and be acquired from marketing associated with multiple departments.
   *   This field is only used to fire department-specific pixels for certain pixel
   *   implementations (e.g. Facebook) in order to build audiences for similar consumers.
   *   It should *not* be used for general department analytics, or attached to our own
   *   tracking events.
   */
  public setDepartment(departmentSlug: string) {
    if (this._disabled) {
      return
    }

    this._department.set(departmentSlug)

    this._trackers.forEach((tracker) => {
      if (tracker.setDepartment) {
        tracker.setDepartment(departmentSlug)
      }
    })
  }

  /**
   * Get the current department for the tracked user.
   *
   * Note: It is generally a bad idea to rely on this field externally. See the note in
   *   `setDepartment` for more context.
   */
  public getDepartment() {
    return this._department.get()
  }

  /**
   * Set the current page key for future tracked events. This should be updated as the
   *   user navigates around the site.
   *
   * Note: Unlike most other tracking context (e.g. the current department), the page key
   *   is stored in memory vs. localStorage. Events fired before setting the page key
   *   will *never* have a page key set, even if the user was previously tracked in the
   *   same browser.
   */
  public setPageKey(pageKey?: string) {
    if (this._disabled) {
      return
    }

    this._pageKey = pageKey

    this._trackers.forEach((tracker) => {
      if (tracker.setPageKey) {
        tracker.setPageKey(pageKey)
      }
    })
  }

  /**
   * Get the current page key.
   */
  public getPageKey() {
    return this._pageKey
  }

  public setConnectWidgetParentUrl(parentUrl?: string) {
    if (this._disabled) {
      return
    }

    this._parentUrl = parentUrl

    this._trackers.forEach((tracker) => {
      if (tracker.setConnectWidgetParentUrl) {
        tracker.setConnectWidgetParentUrl(parentUrl)
      }
    })
  }

  public getConnectWidgetParentUrl() {
    return this._parentUrl
  }

  /**
   * Enable session recording (via a session recording tracker like Asayer)
   *
   * Note: All logged-in users will be recorded automatically, and a sample of logged-out
   *   users will be recorded as well. Use this method to explicitly enable session
   *   recording for "high value" users.
   */
  public record() {
    if (this._disabled) {
      return
    }

    this._trackers.forEach((tracker) => {
      if (tracker.record) {
        tracker.record()
      }
    })
  }

  /**
   * Reset all state associated with the tracked user: identifiers, attribution data, and
   *   PII. The notable exception is the `browserId` which is persisted forever.
   */
  public reset(newSessionId: string) {
    if (this._disabled) {
      return
    }

    // Reset the session ID and clear the other tracking IDs
    if (this._sessionId) {
      this._sessionId.set(newSessionId)
    }

    this._userId?.reset()
    this._consumerId?.reset()
    this._businessUserId?.reset()
    this._partnerUserId?.reset()
    this._operatorId?.reset()

    // Reset the local attribution model
    this._attribution.reset()

    // Reset the user traits
    this._traits.reset()

    // Reset the user's department
    this._department.reset()

    // Reset the page key
    this._pageKey = undefined

    // Reset the CCPA consent
    this._ccpaConsent = null
    store.set('ccpaConsent', this._ccpaConsent)

    // Allow individual trackers to reset their internal state
    this._trackers.forEach((tracker) => {
      if (tracker.reset) {
        tracker.reset()
      }
    })
  }

  /**
   * Disable event tracking (e.g. when a user is being impersonated)
   */
  public disable() {
    if (this._disabled) {
      return
    }

    this._disabled = true

    // Allow individual trackers to respond to being disabled
    this._trackers.forEach((tracker) => {
      if (tracker.disable) {
        tracker.disable()
      }
    })
  }

  /**
   * Enable event tracking
   */
  public enable() {
    if (!this._disabled) {
      return
    }

    this._disabled = false

    this._trackers.forEach((tracker) => {
      if (tracker.enable) {
        tracker.enable()
      }
    })
  }

  /**
   * Mark the user as opting out of tracking for CCPA compliance.
   *
   * This is similar to disabling tracking altogether, but less aggressive. Some trackers,
   *   such as the Console Tracker and our internal Rudderstack Tracker, for example, are
   *   CCPA-compliant and remain enabled.
   */
  public optOutCCPA() {
    this._ccpaConsent = false
    store.set('ccpaConsent', this._ccpaConsent)

    // Allow individual trackers to respond to the opt-out
    this._trackers.forEach((tracker) => {
      if (tracker.optOutCCPA) {
        tracker.optOutCCPA()
      }
    })
  }

  /**
   * Mark the user as opting in to tracking for CCPA compliance.
   *
   * CCPA is an opt-out system, so it's generally unnecessary to implement this method in
   *   specific trackers. Invoking this method sets a global flag indicating that the user
   *   has provided an explicit consent, which can be used to determine whether to display
   *   the consent banner (accessible via `getCCPAConsent`).
   */
  public optInCCPA() {
    this._ccpaConsent = true
    store.set('ccpaConsent', this._ccpaConsent)

    // Allow individual trackers to respond to the opt-in
    this._trackers.forEach((tracker) => {
      if (tracker.optInCCPA) {
        tracker.optInCCPA()
      }
    })
  }

  /**
   * Get the user's current CCPA consent state.
   *
   *  - `null` if they have not responded to a consent affordance
   *  - `true` if they have explicitly opted-in
   *  - `false` if they have opted-out
   */
  public getCCPAConsent() {
    return this._ccpaConsent
  }
}
