import store from '../store'
import { TouchPointAttributes } from '../types/touches'

/**
 * Represents a user visiting the site from an external source (paid or otherwise)
 */
export interface TouchPoint {
  timestamp: Date
  attributes: TouchPointAttributes
}

/**
 * Model a user's clicks/touchpoints with our application over time.
 *
 * WARNING:
 * -------
 *
 * These client-side models are not intended to be used a system of record for marketing attribution.
 *   Touchpoints ae persisted in localStorage, meaning this model is browser-specific. Due to the
 *   inherent multi-device nature of our product, a single-browser view of attribution is generally
 *   insufficient for any kind of complex analysis.
 *
 * The client-side attribution modelling exists primarily for backwards compatibility: as of December 2019,
 *   we send attribution data with every Segment event. This event-level attribution is used for some
 *   analytics queries, and a snapshot is persisted to the backend when a user registers (as "User Source"
 *   data, which is used for campaign management).
 */
export default class AttributionModel {
  private _firstTouch?: TouchPoint
  private _lastTouch?: TouchPoint

  public constructor() {
    const [firstTouch, lastTouch] = this.load()

    this.setFirstTouch(firstTouch)
    this.setLastTouch(lastTouch)
  }

  /**
   * Update the first touch in memory & local storage.
   */
  private setFirstTouch(touch: TouchPoint | null) {
    if (!touch) {
      delete this._firstTouch
      store.remove('firstTouch')
    } else {
      this._firstTouch = touch
      store.set('firstTouch', this._firstTouch)
    }
  }

  /**
   * Update the last touch in memory & local storage.
   */
  private setLastTouch(touch: TouchPoint | null) {
    if (!touch) {
      delete this._lastTouch
      store.remove('lastTouch')
    } else {
      this._lastTouch = touch
      store.set('lastTouch', this._lastTouch)
    }
  }

  /**
   * Fetch the touchpoints from localStorage
   */
  private load(): [TouchPoint, TouchPoint] {
    const firstTouch = store.get('firstTouch') as any
    const lastTouch = store.get('lastTouch') as any

    // Store2 can't automatically deserialize Dates, so we do it explicitly
    if (firstTouch) firstTouch.timestamp = new Date(firstTouch.timestamp)
    if (lastTouch) lastTouch.timestamp = new Date(lastTouch.timestamp)

    return [firstTouch, lastTouch]
  }

  /**
   * Erase the recorded touchpoints
   */
  public reset() {
    this.setFirstTouch(null)
    this.setLastTouch(null)
  }

  /**
   * Record a new touchpoint. Backdating is supported.
   */
  public touch(attributes: TouchPointAttributes, timestamp = new Date()): void {
    const touchPoint: TouchPoint = {
      timestamp,
      attributes
    }

    if (!this._firstTouch) {
      this.setFirstTouch(touchPoint)
    }

    this.setLastTouch(touchPoint)
  }

  /**
   * Compute first-touch attribution for the recorded touchpoints
   */
  public get firstTouch() {
    return this._firstTouch
  }

  /**
   * Compute last-touch attribution for the recorded touchpoints
   */
  public get lastTouch() {
    return this._lastTouch
  }
}
