import { Event } from '../Event'
import { ViewerContext } from '../../constants'

import { VideoPlaybackEventProperties } from '../types/consumer'

export class VideoPlaybackPausedEvent extends Event<VideoPlaybackEventProperties> {
  static displayName = 'Video Playback Paused'

  static allowedViewerContexts = [ViewerContext.Consumer]
}
