import { Event } from '../Event'
import { ViewerContext } from '../../constants'

export interface ViewCurationLtvEventProperties {
  value: number
  currency: string
  content_category: string
}

export class ViewCurationLtvEvent extends Event<ViewCurationLtvEventProperties> {
  static displayName = 'View Curation LTV'

  static allowedViewerContexts = [ViewerContext.Consumer]
}
