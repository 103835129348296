import { Event } from '../Event'
import { ViewerContext } from '../../constants'

export interface ExperimentViewedEventProperties {
  experiment: string
  variation: string
  subject_id: string
  subject_attributes: { [key: string]: string }
  change_number: number
  seed: number
  bucket: number
  include_in_analysis: boolean
}

/**
 * Fires when a user is exposed to an A/B experiment. This event will fire every time
 *   an end-user is exposed to the experiment.
 *
 * TODO:
 *  - Link to a general document outlining our experimentation methodology.
 *  - Document how "User IDs" differ from "Experiment User IDs" and where they are tracked.
 *  - Document `change_number` and `non_interaction` properties.
 */
export class ExperimentViewedEvent extends Event<ExperimentViewedEventProperties> {
  static displayName = 'Experiment Viewed'

  static allowedViewerContexts = [
    ViewerContext.Consumer,
    ViewerContext.BusinessUser,
    ViewerContext.PartnerUser,
    ViewerContext.Operator,
  ]
}
