import { Event } from '../Event'
import { ViewerContext } from '../../constants'
import { OrderProperties } from '../types/consumer'

export interface OrderCompletedEventProperties extends OrderProperties {
  checkout_id: string
  payment_method: string
  payment_type: string
}

export class OrderCompletedEvent extends Event<OrderCompletedEventProperties> {
  static displayName = 'Order Completed'

  static allowedViewerContexts = [ViewerContext.Consumer]
}
