import { SpecificTracker } from '../types/tracker'

export default class RedditAdsTracker implements SpecificTracker {
  public constructor() {
    if (!window.rdt) {
      throw new Error(
        'The Reddit Pixel snippet is missing, but must be installed to use the RedditTracker.'
      )
    }
  }

  private get rdt() {
    return window.rdt!
  }

  public trackPageViewed() {
    this.rdt('track', 'PageVisit')
  }

  public trackProductsSearchResultsViewed() {
    this.rdt('track', 'Search')
  }

  public trackProductAdded() {
    this.rdt('track', 'AddToCart')
  }

  public trackOrderCompleted() {
    this.rdt('track', 'Purchase')
  }

  public trackLeadCreated() {
    this.rdt('track', 'Lead')
  }

  public trackExpertApplicationSubmitted() {
    this.rdt('track', 'Custom', { customEventName: 'Expert Application' });
  }

  public trackUserRegistered() {
    this.rdt('track', 'SignUp')
  }
}
