import Id from './base'

/**
 * A PartnerUserId represents a user with access to the partner app (i.e. a brand, agency,
 *   or retailer)
 *
 * See: https://tracking.corp.curated.com/guide/sessionization.html#identification
 */
export default class PartnerUserId extends Id {
  public constructor(underlyingId: string) {
    super('partnerUserId', underlyingId)
  }
}
