import { Event } from '../Event'
import { ViewerContext } from '../../constants'

export interface RichCardClickedEventProperties {
  rich_card_type: string
  expert_id: string
}

export class RichCardClickedEvent extends Event<RichCardClickedEventProperties> {
  static displayName = 'Rich Card Clicked'

  static allowedViewerContexts = [ViewerContext.Consumer]
}
