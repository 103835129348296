import { ViewerContext } from '../constants'

export default function displayNameForViewerContext(identity: ViewerContext): string {
  switch (identity) {
    case ViewerContext.Consumer:
      return 'consumer'
    case ViewerContext.BusinessUser:
      return 'business user'
    case ViewerContext.PartnerUser:
      return 'partner user'
    case ViewerContext.Operator:
      return 'operator'
  }
}
