import { Event } from '../Event'
import { ViewerContext } from '../../constants'
import { SellableProperties } from '../types/consumer'

export interface ProductAddedEventProperties extends SellableProperties {
  quantity: number
  expert_curated_item_id?: string
  source?: string
}

export class ProductAddedEvent extends Event<ProductAddedEventProperties> {
  static displayName = 'Product Added'

  static allowedViewerContexts = [ViewerContext.Consumer]
}
