import Id from './base'

/**
 * A ConsumerId represents a consumer which is bound within a Tenant.
 *
 * This is a replacement for how we've used UserId in the past.
 *
 * See: https://tracking.corp.curated.com/guide/sessionization.html#identification
 */
export default class ConsumerId extends Id {
  public constructor(underlyingId: string) {
    super('consumerId', underlyingId)
  }
}
