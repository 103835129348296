import { Event } from '../Event'
import { ViewerContext } from '../../constants'

export interface ExperimentMetricMeasuredEventProperties {
  metrics: Array<{
    key: string
    value?: number
  }>
}

export class ExperimentMetricMeasuredEvent extends Event<ExperimentMetricMeasuredEventProperties> {
  static displayName = 'Experiment Metric Measured'

  static allowedViewerContexts = [
    ViewerContext.Consumer,
    ViewerContext.BusinessUser,
    ViewerContext.Operator,
    ViewerContext.PartnerUser,
  ]
}
