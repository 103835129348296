import { Event } from '../Event'
import { ViewerContext } from '../../constants'
import { OrderProperties } from '../types/consumer'

export interface CheckoutStartedEventProperties extends OrderProperties {
  checkout_id: string
}

export class CheckoutStartedEvent extends Event<CheckoutStartedEventProperties> {
  static displayName = 'Checkout Started'

  static allowedViewerContexts = [ViewerContext.Consumer]
}
