import Id from './base'

/**
 * A unique identifier for a given browser. This ID is never reset (unless the user
 *   manually clears their browser storage) and is not user-specific.
 */
export default class BrowserId extends Id {
  public constructor() {
    super('browserId')
  }
}
