import Id from './base'

/**
 * A OperatorId represents a user with access to the operations app (i.e. HQ employees,
 *   contractors, content editors, etc.)
 *
 * See: https://tracking.corp.curated.com/guide/sessionization.html#identification
 */
export default class OperatorId extends Id {
  public constructor(underlyingId: string) {
    super('operatorId', underlyingId)
  }
}
