import { SpecificTracker } from '../types/tracker'
import * as Events from '../events'

export default class BingTracker implements SpecificTracker {
  private get uetq() {
    window.uetq = window.uetq || []
    return window.uetq
  }

  public trackOrderCompleted({ properties }: Events.OrderCompletedEvent) {
    this.uetq.push('event', 'Order Completed', {
      revenue_value: parseFloat(properties.value),
      currency: properties.currency,
    })

    this.uetq.push('event', 'Purchase (Assumed Margin)', {
      revenue_value: parseFloat(properties.assumed_margin),
      currency: properties.currency,
    })
  }

  public trackLeadCreated() {
    this.uetq.push('event', 'New Lead')
  }
}
